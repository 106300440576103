


























































































































































import { InputSetups } from "@/mixins/input-setups";
import { ChatConfigSettings } from "@/includes/types/Chat/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import GreetingChatButtonsMessageHelpView from "@/components/HelpViews/Greeting/GreetingChatButtonsMessageHelpView.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import NewUsersHandlerModule
  from "@/includes/logic/Modules/modules/modules/NewUsersHandlerModule/NewUsersHandlerModule";
import {
  InviteValidationGreetingProtection
} from "@/includes/logic/Modules/modules/modules/NewUsersHandlerModule/protection";

import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import MessageEditorWithMediaData, { IMessageTemplate } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator';
import { instanceToPlain } from "class-transformer";

@Component({
  data() {
    return {
      GreetingChatButtonsMessageHelpView,
      MediaEditorMode,
    }
  },
  components: {
    PageTitle,
    ConfigField,
    SwitchInput,
    SelectInput,
    MultiMessageEditorWithMediaInput
  }
})
export default class MainSettings extends Mixins(InputSetups, TariffsTagsHelper, Placeholders) {
  @VModel() model!: ChatConfigSettings['common_config']

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  saveButtonClick() {
    return true
  }

  get chatButtonsMessage() {
    return this.model.chat_buttons_message
  }

  set chatButtonsMessage(value: Array<MessageEditorWithMediaData>) {
    this.model.chat_buttons_message = instanceToPlain(value) as Array<IMessageTemplate>
  }

  mounted() {
    this.$baseTemplate.saveButton.show()
  }
}
